@import "../stylesheets/config.scss";

$Room--vMargin: 1.5rem;

.Room {
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Room__notes {
  flex-grow: 100;

  // Inner
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  justify-content: space-between;
  align-content: stretch;

  padding: 0 0.5rem;
}

.Room__info {
  flex-grow: 1;

  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: $redAltColor;
  }

  .Room__info-block {
   margin: 0.5rem 0;
  }

  // Inner
  display: flex;
  flex-direction: column;
  justify-content: center;

  // padding: 1.5rem;

  .Room__info-centered {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      margin: $Room--vMargin 0;
    }
  }

  .Room__info-bottom > * {
    margin: $Room--vMargin 0;
  }

  .Room__info-footer {
    background-color: $darkLighterColor;

    > * {
      margin: $Room--vMargin 0;
      // margin-bottom: 0 !important;
    }
  }
}

// Shared among children

.Room__status {
  font-style: italic;
}
