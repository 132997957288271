@import "../stylesheets/config.scss";

.StatusHost {
  align-self: center;

  // padding-top: 1rem;
  // border-top: 1px solid $redColor;
  // background-color: $darkLighterColor;
  // border-radius: $radiusBigger;
  // padding: 1rem;
  h2 {
    margin-top: 0;
    color: $whiteColor !important;
  }
}
