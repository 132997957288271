@import "stylesheets/config.scss";

.App {
  min-height: 100%;
  // padding-bottom: 1rem;
  flex-shrink: 0;
  overflow-y: auto;

  background-color: $darkColor;
  color: $whiteColor;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App__main {
  // Take the remaining vertical space
  flex-grow: 1;
  flex-shrink: 0;

  // Inner
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  overflow-y: auto;
}

// Utils

.App__global-message-title {
  font-size: 2rem;
  font-family: $fontTitle;
}

.retro-font {
  font-family: $fontTitle;
}
