@import "../stylesheets/config.scss";

.Link {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;

  background-color: $darkLighterColor;
  border-radius: $radiusBigger;

  cursor: pointer;

  &:hover {
    background-color: $darkLighterLighterColor;
  }

  &:active {
    background-color: $darkLighterColor;
  }

  position: relative;
}

.Link > * {
  padding: 0.5rem;
}

.Link__text {
  font-family: monospace;
  position: relative;
}

.Link__clipboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 4rem;

  img {
    height: 100%;
    width: auto;
  }

  font-family: $fontTitle;
}
