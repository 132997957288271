.Header {
  padding: 2rem;
  padding-bottom: 0rem;

  text-align: center;

  h1 {
    margin: 0;
  }
}
