$darkColor: #262524;
$darkLighterColor: rgba(56, 55, 53, 0.588);
$darkLighterLighterColor: rgba(70, 70, 70, 0.588);
$disabledColor: rgba(236, 236, 236, 0.588);

$whiteWhiteColor: white;
$whiteColor: #F2F2F2;
$whiteYellowColor: #F2E6D8;

$redAltColor: #F20505;
$redColor: rgb(242, 53, 53);

$radius: 3px;
$radiusBigger: 0.5rem;

$font: 'Helvetica Neue', sans-serif;
$fontTitle: 'Press Start 2P', sans-serif;
