@import "../stylesheets/config.scss";

.Column {
  margin: 2rem 0.5rem;

  flex: 1 0 0;

  // Inner
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    margin: 0;
    text-align: center;
  }
}

.Column > * {
  margin-top: 1rem;
}
