@import "./config.scss";

// Inputs
input, button, textarea {
  padding: 0.4em;
  font-size: 1em;

  border: none;
  border-radius: $radius;
}

// Buttons
button, input[type=submit] {
  background-color: $redAltColor;
  color: $whiteColor;

  cursor: pointer;

  &:hover {
    background-color: $redColor;
  }
  &:active {
    background-color: $redAltColor;
  }
}

html {
  font-family: $font;
}

button:disabled {
  background-color: $disabledColor;
}
