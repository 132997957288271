@import "../stylesheets/config.scss";

.Note {
  background-color: $whiteWhiteColor;
  width: 100%;

  padding: 0.5em;
  border-radius: $radius;
  color: $darkColor;
  text-align: justify;

  // #badge-positionning
  // overflow: hidden;

  // #badge-positionning-alt

  textarea {
    padding: 0;
    resize: vertical;
    font-family: $font;
    width: 100%;
    overflow: hidden;
    // background-color: $whiteWhiteColor;
  }
}

.Note > p {
  margin-bottom: 0;
}

.Note__badge {
  // #badge-positionning
  // float: right;
  // position: relative;
  // top: 0.3rem;
  // left: 0.3rem;

  // #badge-positionning-alt
  float: right;
  position: relative;
  top: 0.3rem;
  left: 1rem;

  // Badge style
  min-width: 3rem;
  text-align: center;
  color: $whiteColor;
  padding: 0.2rem 0.3rem;
  margin: 0.2rem;
  background-color: $redAltColor;
  border-radius: $radius;

  // margin-bottom: 0;

  // #badge-positionning-alt
  margin-bottom: -0.5rem;
}

.Note__edit {
  padding: 0.2rem 0.7rem;
}

// #badge-positionning-alt
.Note:after {
  content: "";
  clear: both;
  display: block;
}
